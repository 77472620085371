import { Link } from "react-router-dom";
import { CompanyLogo } from "./CompanyLogo";
import { Drawer } from "flowbite";
import ActivityIndicator from "../shared/ActivityIndicator";
import { Popover } from "@headlessui/react";
import { useAppSelector } from "../../app/hooks";
import { selectUser } from "../../app/features/userSlice";
import { selectSelectedCompany } from "../../app/features/companySlice";
import useAuth from "../../hooks/useAuth";
import { NotificationMenu } from "../notification/NotificationMenu";

export function DashboardNavbar() {
  const loggedInUser = useAppSelector(selectUser);
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const { signingOut, handleSignOutUser } = useAuth();
  const $drawerTargetEl = document.getElementById("logo-sidebar");

  const drawer = $drawerTargetEl ? new Drawer($drawerTargetEl) : undefined;
  return (
    <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <button
              data-drawer-target="logo-sidebar"
              data-drawer-show="logo-sidebar"
              data-drawer-toggle="logo-sidebar"
              aria-controls="logo-sidebar"
              type="button"
              onClick={() => {
                if (drawer && drawer.isHidden()) {
                  drawer.show();
                } else {
                  drawer?.hide();
                }
              }}
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                ></path>
              </svg>
            </button>
            <CompanyLogo />
          </div>
          <div className="flex items-center">
            <div className="flex items-center ml-3 relative gap-x-5">
              <NotificationMenu />
              <Popover className="relative">
                <Popover.Button>
                  <div className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
                    {loggedInUser?.photoURL ? (
                      <img
                        className="w-8 h-8 rounded-full"
                        src={loggedInUser?.photoURL}
                        alt="user photo"
                      />
                    ) : (
                      <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                        <span className="font-medium text-gray-600 dark:text-gray-300 uppercase">
                          {loggedInUser?.firstName?.slice(0, 2)}
                        </span>
                      </div>
                    )}
                  </div>
                </Popover.Button>
                <Popover.Panel className="absolute z-40 dark:bg-[#222529] right-0">
                  {loggedInUser && (
                    <div
                      className="text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                      id="dropdownMenu"
                    >
                      <div className="px-4 py-3" role="none">
                        <p
                          className="text-sm text-gray-900 dark:text-white"
                          role="none"
                        >
                          {loggedInUser.firstName} {loggedInUser.lastName}
                        </p>
                        <p
                          className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                          role="none"
                        >
                          {loggedInUser.email}
                        </p>
                      </div>
                      <ul className="py-1" role="none">
                        <li>
                          <Link
                            to={`/dashboard/${selectedCompany?.id}/settings/profile`}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                            role="menuitem"
                          >
                            Settings
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/${selectedCompany?.id}/bankRecords`}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                            role="menuitem"
                          >
                            Earnings
                          </Link>
                        </li>
                        <li>
                          <div
                            onClick={handleSignOutUser}
                            className="cursor-pointer flex items-center gap-x-3 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                            role="menuitem"
                          >
                            Sign out {signingOut && <ActivityIndicator />}
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </Popover.Panel>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
