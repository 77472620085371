import moment from "moment";
import {
  Invoice,
  InvoiceFrequency,
  InvoiceItem,
  InvoiceStatus,
  PaymentCurrency,
  PaymentMethod,
  RecurringInvoiceStatus,
} from "../models";

export const propertyOne = {
  images: [
    "https://images.pexels.com/photos/1560065/pexels-photo-1560065.jpeg?auto=compress&cs=tinysrgb&w=800",
    "https://images.pexels.com/photos/3617496/pexels-photo-3617496.jpeg?auto=compress&cs=tinysrgb&w=800",
    "https://images.pexels.com/photos/2587054/pexels-photo-2587054.jpeg?auto=compress&cs=tinysrgb&w=800",
    "https://images.pexels.com/photos/2360673/pexels-photo-2360673.jpeg?auto=compress&cs=tinysrgb&w=800",
    "https://images.pexels.com/photos/1115804/pexels-photo-1115804.jpeg?auto=compress&cs=tinysrgb&w=800",
    "https://images.pexels.com/photos/542403/pexels-photo-542403.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  ],
  id: "123",
  owner: "123",
  company: "123",
  title: "2 Bedroom Flat / Apartment ",
  description:
    "2 bedroom Flat / Apartment for rent Ajao Estate Anthony Village Maryland Lagos renting for ₦2,000,000. See property details on PropertyPro.ng or browse all our range of properties in Anthony Village Maryland Lagos2 bedroom Flat / Apartment for rent in Anthony Village Maryland Lagos Rent 2 bedroom Flat / Apartment for rent 2bedroom flat at anthony in Anthony Village Maryland Lagos is a Flat / Apartment. ✓ Real-time photos. Contact now for quick details on 2bedroom flat at anthony Tastefully finished block of 2 (nos) 2 BEDROOMS FLAT (3t/2b) Ajao Estate, Anthony Village.2m each. Disclaimer This property consists of an advertisement by Jucrops integrated services limited. Propertypro only serves as a medium for the advertisement for this property and is not responsible for selling the property.",
  address: "Ajao Estate Anthony Village Maryland Lagos",
  location: "Maty,Land",
  rent: "₦2,000,000.",
  createdDate: "",
  rentPer: "year",
};

export const invoiceData: Invoice[] = [
  {
    amount: 500,
    recurring: false,
    frequency: InvoiceFrequency.Monthly,
    acceptPartialPayment: true,
    remittanceAccount: "",
    amountPaid: 250,
    balanceDue: 250,
    partialPayments: [
      { date: 1643190000, amount: 250, paymentMethod: PaymentMethod.Cash },
    ],
    createdAt: 1643114400,
    currency: PaymentCurrency.USD,
    customer: { name: "John Doe", email: "johndoe@example.com" },
    companyId: "1",
    customerId: "1",
    date: 1643114400,
    description: "Website redesign project",
    discount: 0,
    dueDate: 1645723200,
    id: "1",
    url: "",
    invoiceNumber: "INV001",
    lineItems: [
      {
        paid: false,
        key: "",
        name: "Web design",
        price: 200,
        amount: 200,
        quantity: 1,
      },
      {
        paid: false,
        key: "",
        name: "Web development",
        price: 300,
        amount: 3000,
        quantity: 1,
      },
    ],
    notes: "Thank you for your business!",
    paymentDate: 1643190000,
    paymentMethod: PaymentMethod.Cash,
    propertyId: "1",
    receiptNumber: "RECEIPT001",
    refundedAmount: 0,
    startDate: 0,
    status: InvoiceStatus.Paid,
    subtotal: 500,
    tax: 0,
    total: 500,
    transactionFee: 0,
    transactionId: "TRANSACTION001",
    updatedAt: 1643190000,
    customerName: "",
    customerCompanyPhone: "",
    customerCompanyEmail: "",
    customerAdditionalInfo: "",
    senderAdditionalInfo: "",
    customerCompanyAddress: "",
    senderCompanyAddress: "",
    customerCompanyName: "",
    senderCompanyName: "",
    senderCompanyEmail: "",
    senderCompanyPhone: "",
    senderCompanyLogo: "",
    senderName: "",
  },
  {
    amount: 800,
    recurring: false,
    frequency: InvoiceFrequency.Monthly,
    acceptPartialPayment: true,
    remittanceAccount: "",
    amountPaid: 400,
    balanceDue: 400,
    partialPayments: [
      { date: 1643372800, amount: 400, paymentMethod: PaymentMethod.Card },
    ],
    createdAt: 1643287200,
    currency: PaymentCurrency.USD,
    customer: { name: "Jane Smith", email: "janesmith@example.com" },
    companyId: "2",
    customerId: "2",
    date: 1643287200,
    description: "Marketing campaign for new product launch",
    discount: 0,
    dueDate: 1645896000,
    id: "2",
    url: "",
    invoiceNumber: "INV002",
    lineItems: [
      {
        paid: false,
        key: "",
        name: "Marketing strategy development",
        price: 500,
        amount: 500,
        quantity: 1,
      },
      {
        paid: false,
        key: "",
        name: "Social media ads",
        price: 300,
        amount: 500,
        quantity: 1,
      },
    ],
    notes: "Please let us know if you have any questions or concerns!",
    paymentDate: 1643372800,
    paymentMethod: PaymentMethod.Card,
    propertyId: "2",
    receiptNumber: "RECEIPT002",
    refundedAmount: 0,
    startDate: 0,
    status: InvoiceStatus.Paid,
    subtotal: 800,
    tax: 0,
    total: 800,
    transactionFee: 0,
    transactionId: "TRANSACTION002",
    updatedAt: 1643372800,
    customerName: "",
    customerCompanyPhone: "",
    customerCompanyEmail: "",
    customerAdditionalInfo: "",
    senderAdditionalInfo: "",
    customerCompanyAddress: "",
    senderCompanyAddress: "",
    customerCompanyName: "",
    senderCompanyName: "",
    senderCompanyEmail: "",
    senderCompanyPhone: "",
    senderCompanyLogo: "",
    senderName: "",
  },
  {
    id: "inv-19",
    url: "INV-",
    invoiceNumber: "INV-19",
    companyId: "user-14",
    customerId: "user-14",
    customer: { name: "Tony Smith", email: "tonysmith@example.com" },
    propertyId: "prop-16",
    amount: 345.67,
    subtotal: 300,
    tax: 45.67,
    total: 345.67,
    description: "Landscaping Services",
    date: new Date("2022-03-15").getTime(),
    createdAt: new Date("2022-03-15").getTime(),
    updatedAt: new Date("2022-04-01").getTime(),
    customerName: "",
    customerCompanyPhone: "",
    customerCompanyEmail: "",
    customerAdditionalInfo: "",
    senderAdditionalInfo: "",
    customerCompanyAddress: "",
    senderCompanyAddress: "",
    customerCompanyName: "",
    senderCompanyName: "",
    senderCompanyEmail: "",
    senderCompanyPhone: "",
    senderCompanyLogo: "",
    senderName: "",
    dueDate: new Date("2022-04-30").getTime(),
    status: InvoiceStatus.Sent,
    paymentMethod: PaymentMethod.Bank,
    currency: PaymentCurrency.USD,
    recurring: false,
    frequency: InvoiceFrequency.Monthly,
    acceptPartialPayment: true,
    remittanceAccount: "",
    amountPaid: 0,
    balanceDue: 345.67,
    lineItems: [
      {
        paid: false,
        key: "",
        name: "Lawn Mowing",
        price: 100,
        amount: 100,
        quantity: 1,
      },
      {
        paid: false,
        key: "",
        name: "Hedge Trimming",
        price: 150,
        amount: 150,
        quantity: 1,
      },
      {
        paid: false,
        key: "",
        name: "Weed Control",
        price: 50,
        amount: 50,
        quantity: 1,
      },
    ],
    transactionFee: 10,
    discount: 0,
    partialPayments: [],
  },
  {
    id: "inv-20",
    url: "INV-",
    invoiceNumber: "INV-20",
    companyId: "user-15",
    customerId: "user-15",
    customer: { name: "Bay Cat", email: "baycat@example.com" },
    propertyId: "prop-17",
    amount: 1200,
    subtotal: 1000,
    tax: 200,
    total: 1200,
    description: "Monthly Rent",
    date: new Date("2022-03-01").getTime(),
    createdAt: new Date("2022-03-01").getTime(),
    updatedAt: new Date("2022-04-01").getTime(),
    customerName: "",
    customerCompanyPhone: "",
    customerCompanyEmail: "",
    customerAdditionalInfo: "",
    senderAdditionalInfo: "",
    customerCompanyAddress: "",
    senderCompanyAddress: "",
    customerCompanyName: "",
    senderCompanyName: "",
    senderCompanyEmail: "",
    senderCompanyPhone: "",
    senderCompanyLogo: "",
    senderName: "",
    dueDate: new Date("2022-04-30").getTime(),
    status: InvoiceStatus.Overdue,
    paymentMethod: PaymentMethod.Cash,
    currency: PaymentCurrency.USD,
    recurring: false,
    frequency: InvoiceFrequency.Monthly,
    acceptPartialPayment: false,
    remittanceAccount: "",
    amountPaid: 0,
    balanceDue: 1200,
    lineItems: [
      {
        paid: false,
        key: "",
        name: "Rent",
        price: 1000,
        amount: 1000,
        quantity: 1,
      },
      {
        paid: false,
        key: "",
        name: "Late Fee",
        price: 200,
        amount: 200,
        quantity: 1,
      },
    ],
    discount: 0,
    partialPayments: [],
    transactionFee: 0,
  },

  //...and so on for the remaining 18 invoices
];

export const recurringInvoiceData: Invoice[] = [
  {
    amount: 500,
    acceptPartialPayment: true,
    remittanceAccount: "",
    amountPaid: 250,
    balanceDue: 250,
    partialPayments: [
      { date: 1643190000, amount: 250, paymentMethod: PaymentMethod.Cash },
    ],
    createdAt: 1643114400,
    currency: PaymentCurrency.USD,
    customer: { name: "John Doe", email: "johndoe@example.com" },
    companyId: "1",
    customerId: "1",
    date: 1643114400,
    description: "Monthly rent payment",
    discount: 0,
    dueDate: 1645723200,
    id: "1",
    url: "",
    invoiceNumber: "INV001",
    lineItems: [
      {
        paid: false,
        key: "",
        name: "Web design",
        price: 200,
        amount: 200,
        quantity: 1,
      },
      {
        paid: false,
        key: "",
        name: "Web development",
        price: 300,
        amount: 300,
        quantity: 1,
      },
    ],
    notes: "Thank you for your business!",
    paymentDate: 1643190000,
    paymentMethod: PaymentMethod.Cash,
    propertyId: "1",
    receiptNumber: "RECEIPT001",
    recurring: true,
    refundedAmount: 0,
    startDate: 1640978400000, // Jan 1, 2022
    endDate: 1672514400000, // Dec 31, 2022
    status: InvoiceStatus.Paid,
    subtotal: 500,
    tax: 0,
    total: 500,
    transactionFee: 0,
    transactionId: "TRANSACTION001",
    updatedAt: 1643190000,
    customerName: "",
    customerCompanyPhone: "",
    customerCompanyEmail: "",
    customerAdditionalInfo: "",
    senderAdditionalInfo: "",
    customerCompanyAddress: "",
    senderCompanyAddress: "",
    customerCompanyName: "",
    senderCompanyName: "",
    senderCompanyEmail: "",
    senderCompanyPhone: "",
    senderCompanyLogo: "",
    senderName: "",
    frequency: InvoiceFrequency.Monthly,
    nextPaymentDate: moment(1640978400000).add(1, "month").toDate().getTime(),
    recurringInvoiceStatus: RecurringInvoiceStatus.Active,
  },
  {
    amount: 800,
    acceptPartialPayment: true,
    remittanceAccount: "",
    amountPaid: 400,
    balanceDue: 400,
    partialPayments: [
      { date: 1643372800, amount: 400, paymentMethod: PaymentMethod.Card },
    ],
    createdAt: 1643287200,
    currency: PaymentCurrency.USD,
    customer: { name: "Jane Smith", email: "janesmith@example.com" },
    companyId: "2",
    customerId: "2",
    date: 1643287200,
    description: "Quarterly maintenance fee",
    frequency: InvoiceFrequency["Every three months"],
    startDate: 1640978400000, // Jan 1, 2022
    endDate: 1675125600000, // Mar 31, 2023
    discount: 0,
    dueDate: 1645896000,
    id: "2",
    url: "",
    invoiceNumber: "INV002",
    lineItems: [
      {
        paid: false,
        key: "",
        name: "Marketing strategy development",
        price: 500,
        amount: 500,
        quantity: 1,
      },
      {
        paid: false,
        key: "",
        name: "Social media ads",
        price: 300,
        amount: 300,
        quantity: 1,
      },
    ],
    notes: "Please let us know if you have any questions or concerns!",
    paymentDate: 1643372800,
    paymentMethod: PaymentMethod.Card,
    propertyId: "2",
    receiptNumber: "RECEIPT002",
    recurring: false,
    refundedAmount: 0,

    status: InvoiceStatus.Paid,
    subtotal: 800,
    tax: 0,
    total: 800,
    transactionFee: 0,
    transactionId: "TRANSACTION002",
    updatedAt: 1643372800,
    customerName: "",
    customerCompanyPhone: "",
    customerCompanyEmail: "",
    customerAdditionalInfo: "",
    senderAdditionalInfo: "",
    customerCompanyAddress: "",
    senderCompanyAddress: "",
    customerCompanyName: "",
    senderCompanyName: "",
    senderCompanyEmail: "",
    senderCompanyPhone: "",
    senderCompanyLogo: "",
    senderName: "",
    nextPaymentDate: moment(1640978400000).add(3, "month").toDate().getTime(),
    recurringInvoiceStatus: RecurringInvoiceStatus.Active,
  },

  //...and so on for the remaining 18 invoices
];
export const lineItems: InvoiceItem[] = [
  {
    paid: true,
    key: "1",
    name: "Marketing strategy development",
    price: 500,
    amount: 5000,
    quantity: 3,
  },
  {
    paid: true,
    key: "2",
    name: "Social media ads",
    price: 300,
    amount: 300,
    quantity: 1,
  },
  {
    paid: true,
    key: "3",
    name: "Web design",
    price: 200,
    amount: 200,
    quantity: 3,
  },
  {
    paid: false,
    key: "4",
    name: "Web development",
    price: 300,
    amount: 300,
    quantity: 2,
  },
  {
    paid: false,
    key: "5",
    name: "Lawn Mowing",
    price: 100,
    amount: 100,
    quantity: 5,
  },

  {
    paid: false,
    key: "6",
    name: "Hedge Trimming",
    price: 150,
    amount: 150,
    quantity: 1,
  },
  {
    paid: false,
    key: "7",
    name: "Weed Control",
    price: 50,
    amount: 50,
    quantity: 1,
  },
];
